export const config = {
    version: '5.0',
    build: (new Date()).getFullYear().toString() + (new Date()).getMonth().toString() + (new Date()).getDate().toString() + (new Date()).getHours().toString() + (new Date()).getMinutes().toString(),
    env: 'prod',
    apiAddress: 'https://api.andra-ai.com',
    theme: 'dark',
    imidServer: 'https://id.icymat.com/',
    imidOAuthId: '2c5ed8c8c25912a3bb6f901876afaaf5269666fe.id.icymat.pl',
    icymat_assistant: false,
    special_colors: null
};
