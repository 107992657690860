import React from 'react';

export class LicenseComponent extends React.Component {
    /**
     * Constructor
     */
    constructor(props)
    {
        super(props);

        let textClass = 'imd-text-contrast';
        if (this.props.textClass !== undefined) {
            textClass = this.props.textClass;
        }

        this.state = {
            textClass: textClass
        };
    }

    render() {
        /* eslint-disable */
        return (
            <div className={this.state.textClass} style={{textAlign: 'left'}}>
                <h1 className="imd-text-andra"> Andra – licencja oprogramowania
                </h1><small className="imd-text-silver">Ostatnia aktualizacja dnia 23 stycznia 2025r.</small><h2
                className="imd-text-80-andra"> § 1 Definicje
            </h2>
                <ol>
                    <li><strong>Prawo autorskie</strong> – ustawa z&nbsp;dnia 4 lutego 1994 r. o prawie autorskim
                        i&nbsp;prawach pokrewnych (Dz. U. z&nbsp;2016 r. poz. 666).
                    </li>
                    <li><strong>Licencjodawca</strong> – osoba będąca autorem Aplikacji oraz będąca posiadaczem praw
                        autorskich oraz majątkowych do Aplikacji, tj. Mateusz Kolasa (<a
                            href="https://mateuszkolasa.com/" target="_blank"
                            title="https://mateuszkolasa.com/">https://mateuszkolasa.com/</a>).
                    </li>
                    <li><strong>Aplikacja</strong> – chroniona prawem autorskim aplikacja komputerowa pod nazwą Andra,
                        wraz z&nbsp;pozostałymi elementami, niebędącymi programem komputerowym w&nbsp;rozumieniu Prawa
                        autorskiego, ale stanowiące integralną część Oprogramowania takie jak: schematy graficzne, logo,
                        interfejs aplikacji itp.
                    </li>
                    <li><strong>Aktualizacja</strong> – kolejne wersje Oprogramowania, które Licencjodawca udostępnia
                        Licencjobiorcy bez uiszczania dodatkowej opłaty określonej przez Licencjodawcę.
                    </li>
                    <li><strong>API</strong> – odrębna od Aplikacji usługa, będąca integralną częścią Aplikacji,
                        z&nbsp;której korzysta Aplikacja. API udostępnia tylko wybrane dla użytkownika dane zgodne
                        z&nbsp;jego rodzajem subskrypcji.
                    </li>
                    <li><strong>Subskrypcja</strong> – rodzaj konta użytkownika Aplikacji, zdefiniowany przez
                        Licencjodawcę.
                    </li>
                    <li><strong>Usługa</strong> – dostępne w&nbsp;ramach Aplikacji opcje, tj: notatki, kalendarz,
                        finanse (lista wydatków), listy "do zrobienia", przepisy kulinarne, kontrola wagi,
                        połączenie z usługą Nyumba, komunikator, dodatkowe zapytania do Aplikacji.
                    </li>
                    <li><strong>Konto IMid</strong> – udostępnione przez Licencjodawcę konto w&nbsp;usłudze IMid
                        używane do logowania i&nbsp;identyfikacji Licencjobiorcy w&nbsp;Aplikacji.
                    </li>
                    <li><strong>Formularz Kontaktowy</strong> – formularz elektroniczny służący do kontaktu znajdujący
                        się pod adresem <a href="https://icymat.com/formularz-kontaktowy" target="_blank">https://icymat.com/formularz-kontaktowy</a>.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 2 Przedmiot licencji
                </h2>
                <ol>
                    <li>Przedmiotem licencji jest aplikacja internetowa wykonana w&nbsp;technologii Progressive Web
                        Application, dostępna przez dowolną przeglądarkę internetową za pośrednictwem sieci Internet.
                    </li>
                    <li>Aplikacja podczas działania łączy się z&nbsp;zewnętrznym API, które jest integralną częścią
                        udostępnionej aplikacji.
                    </li>
                    <li>API może zostać wyłączone co nie uprawnia Licencjobiorcy do składania jakichkolwiek roszczeń.
                    </li>
                    <li>Licencjodawca udziela Licencjobiorcy bezterminowej, niewyłącznej, nieprzenoszalnej
                        licencji na ww. oprogramowanie. Licencja może zostać cofnięta
                        w&nbsp;dowolnym momencie przez Licencjodawcę bez podania przyczyny.
                    </li>
                    <li>Cofnięcie licencji nie uprawnia Licencjobiorcy do dochodzenia jakichkolwiek roszczeń
                        z&nbsp;tytułu cofnięcia licencji.
                    </li>
                    <li>Licencjodawca oświadcza, że jest podmiotem uprawnionym do udzielania licencji i&nbsp;dostawy ww.
                        Aplikacji. Ponadto Licencjodawca oświadcza, że Aplikacja dostarczona Licencjobiorcy jest wolna
                        od wad prawnych i&nbsp;fizycznych.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 3 Koszty zakupu licencji
                </h2>
                <ol>
                    <li>Licencjodawca udostępnia Aplikację Licencjobiorcy na jego wyraźną prośbę nieodpłatnie.
                    </li>
                    <li>Licencjodawca zastrzega sobie prawo do udostępnienia tylko wybranych Usług w&nbsp;ramach
                        Subskrypcji Licencjobiorcy.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 4 Prawa i obowiązki Licencjobiorcy
                </h2>
                <ol>
                    <li>Licencjobiorca jest zobowiązany do przestrzegania warunków niniejszej Licencji.
                    </li>
                    <li>Licencjobiorca ma obowiązek korzystania z&nbsp;Aplikacji wyłącznie na zasadach przewidzianych
                        w&nbsp;ramach specyfikacji.
                    </li>
                    <li>Licencjobiorca zobowiązuje się nie stosować żadnych narzędzi ułatwiających uzyskanie dodatkowych
                        korzyści w&nbsp;Aplikacji.
                    </li>
                    <li>Licencjobiorca zobowiązuje się nie stosować żadnych metod reverse engeringu do przełamania
                        jakichkolwiek zabezpieczeń aplikacji.
                    </li>
                    <li>Licencjobiorca zobowiązuje się do nieprzełamywania jakichkolwiek zabezpieczeń Aplikacji.
                    </li>
                    <li>Licencjobiorca oświadcza, że wszelkie błędy zauważone w&nbsp;trakcie korzystania
                        z&nbsp;aplikacji zostaną zgłoszone autorowi natychmiast poprzez Formularz Kontaktowy.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 5 Prawa i obowiązki Licencjodawcy
                </h2>
                <ol>
                    <li>Licencjodawca jest zobowiązany do udostępnienia Licencjobiorcy Aplikacji za pośrednictwem
                        przeglądarki internetowej pod adresem <a href="https://andra-ai.com/" target="_blank"
                        title="https://andra-ai.com/">https://andra-ai.com/</a>&nbsp;lub
                        <a href="https://andra.icymat.eu/" target="_blank" title="https://andra.icymat.eu/">https://andra.icymat.eu/</a>&nbsp;
                        (w przypadku udostępniania wersji testowej).
                    </li>
                    <li>Licencjodawca zastrzega sobie prawo do zmiany adresu udostępniania Aplikacji.
                    </li>
                    <li>Licencjodawca w&nbsp;ramach usługi bieżącej pomocy technicznej usunie wady lub awarie
                        w&nbsp;ciągu maksymalnie 90 dni.
                    </li>
                    <li>Licencjodawca zastrzega sobie prawo do zmiany treści i&nbsp;zasad licencji w&nbsp;dowolnym
                        czasie. Zmiany będą na bieżąco udostępniane w&nbsp;Aplikacji. Jeśli Licencjobiorca nie zgadza
                        się z&nbsp;wprowadzonymi zmianami przysługuje mu prawo do natychmiastowego zakończenia
                        korzystania z&nbsp;programu i&nbsp;żądania usunięcia swoich danych.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 6 Autorskie prawa majątkowe
                </h2>
                <ol>
                    <li>Licencjodawca oświadcza, iż jest właścicielem autorskich praw majątkowych do Aplikacji.
                    </li>
                    <li>Licencjodawca udziela licencji na Aplikację Andra, zgodnie z&nbsp;Prawem autorskim tylko
                        i&nbsp;wyłącznie na korzystanie z&nbsp;Aplikacji na stronie <a
                            href="https://andra-ai.com/" target="_blank"
                            title="https://andra-ai.com/">https://andra-ai.com/</a>&nbsp;
                        lub innej wynmionej w pozostałych punktach Licencji.
                    </li>
                    <li>Licencjodawca zabrania wprowadzania w&nbsp;Aplikacji jakichkolwiek zmian.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 7 Czas trwania licencji i zasady jej wypowiadania
                </h2>
                <ol>
                    <li>Niniejsza umowa została zawarta na czas nieoznaczony.
                    </li>
                    <li>Każda ze stron może wypowiedzieć niniejszą Umowę ze skutkiem natychmiastowym w&nbsp;przypadku,
                        gdy druga strona w&nbsp;sposób rażący narusza istotne postanowienia Licencji.
                    </li>
                    <li>Wypowiedzenie Licencji ze strony Licencjobiorcy następuje za pośrednictwem formularza dostępnego
                        na stronie (Formularz Kontaktowy). Po otrzymaniu
                        zgłoszenia Licencjodawca zablokuje Aplikację i&nbsp;usunie wszystkie dane Licencjobiorcy
                        w&nbsp;terminie 14 dni od otrzymania zgłoszenia.
                    </li>
                    <li>Wypowiedzenie Licencji ze strony Licencjodawcy może nastąpić w&nbsp;dowolnym momencie
                        i&nbsp;oznacza usunięcie danych Licencjobiorcy w&nbsp;terminie 14 dni od wypowiedzenia Licencji.
                        Informację o wypowiedzeniu Licencjobiorca otrzyma na swój adres poczty elektronicznej skojarzony
                        z&nbsp;Kontem IMid.
                    </li>
                </ol>
                <h2 className="imd-text-80-andra"> § 8 Postanowienia końcowe
                </h2>
                <ol>
                    <li>Wszelkie zmiany i&nbsp;uzupełnienia niniejszej Licencji będą publikowane w&nbsp;Aplikacji.
                    </li>
                    <li>Spory wynikłe z&nbsp;niniejszej Licencji, a nie rozstrzygnięte w&nbsp;drodze negocjacji będą
                        rozstrzygane przez sąd powszechny właściwy ze względu na miejsce zameldowania Licencjodawcy.
                    </li>
                    <li>W sprawach nieuregulowanych niniejszą Umową mają zastosowanie odpowiednie przepisy Ustawy
                        z&nbsp;dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z&nbsp;2016 r. poz. 380) oraz przepisy
                        Ustawy o prawie autorskim i&nbsp;prawach pokrewnych.
                    </li>
                </ol>
            </div>
        );
        /* eslint-enable */
    }
}
